<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <input
            type="text"
            v-model="searchParams.search"
            v-on:keyup.enter="handleSearch"
            class="form-control form-control-solid w-250px me-2"
            placeholder="Search By Excel Name"
          />
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="generalConstants.SEARCH"
            placement="top"
          >
            <button
              type="button"
              @click.prevent="handleSearch"
              class="btn btn-icon btn-light btn-sm me-2"
            >
              <i class="bi bi-search"></i>
            </button>
          </el-tooltip>
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="generalConstants.RESET"
            placement="top"
          >
            <button
              type="button"
              @click.prevent="resetSearch"
              class="btn btn-icon btn-light btn-sm"
            >
              <i class="bi bi-arrow-counterclockwise"></i>
            </button>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :empty-table-text="noDataFound"
        :loading="loading"
        :enable-items-per-page-dropdown="true"
        ref="updateProps"
      >
        <template v-slot:cell-id="{ row: data }">
          {{ data }}
        </template>
        <template v-slot:cell-created_at="{ row: data }">
          {{ formatDateTime(data.created_at) }}
        </template>
        <template v-slot:cell-excel_name="{ row: data }">
          {{ data.excel_name }}
        </template>
        <template v-slot:cell-action="{ row: data }">
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="generalConstants.DOWNLOAD_FILE"
            placement="top"
          >
            <button
              type="button"
              @click.prevent="downloadItem(data.excel_name)"
              class="btn btn-icon btn-light btn-sm me-2"
            >
              <i class="bi bi-download"></i>
            </button>
          </el-tooltip>
          <el-tooltip class="box-item" effect="dark" :content="generalConstants.DELETE" placement="top">
            <button type="button" @click.prevent="remove(data.shipping_package_import_history_id, tableData.findIndex((i) => i.shipping_package_import_history_id === data.shipping_package_import_history_id))" class="btn btn-icon btn-light btn-sm me-2">
              <i class="bi bi-trash text-danger"></i>
            </button>
          </el-tooltip>
        </template>
      </Datatable>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { useRoute, useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import { formatDateTime } from "@/core/helpers/common";
import useEventBus from '../../composable/useEventBus';
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import { ElMessageBox } from "element-plus";

export default {
  name: "package-excel-listing",
  components: {
    Datatable,
  },
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const route = useRoute();
    const router = useRouter();
    const total = ref(0);
    const tableData = ref([]);
    const initPackageExcelData = ref([]);
    const loading = ref(false);
    const generalConstants = globalConstant.general;
    const noDataFound = ref(generalConstants.LOADING);
    const updateProps = ref(null);
    const searchParams = reactive({
      search: "",
      sortby: "latest",
      recordsPerPage: 10,
      pageNub: 1
    });

    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "back"){
        router.push('/shipping-packages');
      }
    })

    const tableHeader = ref([
      {
        name: generalConstants.SRNO,
        key: generalConstants.INDEX,
        sortable: false,
        width: "w-20px",
      },
      {
        name: generalConstants.DATEANDTIME,
        key: "created_at",
        sortable: true,
        width: "w-150px",
      },
      {
        name: generalConstants.FILENAME,
        key: "excel_name",
        width: "w-200px",
        sortable: false,
      },
      {
        name: generalConstants.ACTIONS,
        key: "action",
        width: "w-100px",
        sortable: false,
      },
    ]);

    const downloadItem = (fileName) => {
      if (fileName != "") {
        window.open(
          process.env.VUE_APP_API_URL +
            "../../../PackageImportExcelFiles/" +
            fileName,
          "_blank"
        );
      }
    };

    //Handle search event
    const handleSearch = () => {
      tableData.value.splice(
        0,
        tableData.value.length,
        ...initPackageExcelData.value
      );
      if (searchParams.search !== "") {
        let results = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], searchParams.search)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
        if (results.length == 0) {
          updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
        }
      }
      setCurrentPageBreadcrumbs(
        formatText(route.name) + " (" + total.value + ")",
        [
          { name: "Shipping Packages", path: "shipping-packages" },
          { name: formatText(route.name), active: true },
        ]
      );
    };

    const searchingFunc = (obj, value) => {
      for (let key in obj) {
        if (
          obj[key] &&
          obj[key].length > 0 &&
          !Number.isInteger(obj[key]) &&
          !(typeof obj[key] === "object")
        ) {
          if (obj[key].toUpperCase().indexOf(value.toUpperCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    //Reset search value
    const resetSearch = () => {
      if (searchParams.search.length > 0) {
        searchParams.search = "";
        handleSearch();
      }
    };

    //Get package excel history
    const getPackageExcelHistoryData = async () => {
      loading.value = true;
      await ApiService.post("shipping-packages/get-package-excel-history", {
        params: searchParams,
      })
        .then(({ data }) => {
          if (data.data && data.data.length) {
            tableData.value.splice(0, tableData.value.length, ...data.data);
            initPackageExcelData.value.splice(0, tableData.value.length, ...data.data);
            total.value = data.data.length;
          } else {
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
          }
          loading.value = false;
          setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
          handleSearch();
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let status = response.status;
            let message = response.data.message;
            notificationFire(message, "error");
          }
          loading.value = false;
          tableData.value = [];
          total.value = 0;
        });
    };

    //Delete Shipping Package Import History Record
    const remove = async (storeId, index) => {
      ElMessageBox.confirm(
        generalConstants.DELETE_MESSAGE,
        generalConstants.ALERT_WARNING,
        {
          confirmButtonText: generalConstants.ALERT_CONFIMATION,
          cancelButtonText: generalConstants.ALERT_CANCEL,
          type: "warning",
          center: true,
        }
      ).then(() => {
        ApiService.post("shipping-packages/delete-shipping-import-history-data", {
            id: storeId,
          })
          .then((response) => {
            let status = response.status;
            let message = response.data.message;
            if (status == 200) {
              notificationFire(message, "success");
              tableData.value.splice(index, 1);
              total.value = total.value > 0 ? total.value - 1 : 0;
              setCurrentPageBreadcrumbs(
                formatText(route.name) + " (" + total.value + ")",
                [{ name: formatText(route.name), active: true }]
              );
            }
            if(tableData.value.length == 0){
              updateProps.value.updateProps(total.value,searchParams.recordsPerPage,searchParams.pageNub,generalConstants.NO_DATA_FOUND);
            }
          })
          .catch((error) => {
            let response = error.response;
            if (response) {
              let message = response.data.message;
              notificationFire(message, "error");
            }
          });
      });
    };

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name": generalConstants.BACK , 'action': 'back','class': 'btn-primary','isLoader':false, 'iconClass':"bi bi-arrow-left fs-4" }]);
    }

    //Set breadcrumbs and get the products
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [
        { name: "Shipping Packages", path: "shipping-packages" },
        { name: formatText(route.name), active: true },
      ]);
      generateHeaderToolbarButtons();
      await getPackageExcelHistoryData();
    });

    return {
      tableData,
      getPackageExcelHistoryData,
      total,
      loading,
      resetSearch,
      formatDateTime,
      downloadItem,
      generalConstants,
      searchParams,
      tableHeader,
      noDataFound,
      handleSearch,
      updateProps,
      remove
    };
  },
};
</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
</style>